.main_nav {

  display:  flex;
  align-items:  baseline;
  gap:  1rem;

  padding:  .5rem;

  font-size:  2rem;

  border-bottom-style:  solid;
  border-bottom-color:  #495057;
  border-bottom-width:  1px;

}

.menu_button {


}

.menu_button:hover {

  cursor:  pointer;

}