

.app {

  display:  flex;

  flex-direction:  column;

  height:  100vh;


}



.mid_section {

  display:  flex;

  flex-grow:  1;


}

.content {
  padding:  0 1rem 1rem 1rem;

  max-width:  70ch;
}

