

.left_panel {

	display:  flex;
	flex-direction:  column;
	align-items:  left;

	gap:  .5rem;

	padding:  1rem;

	border-right-style:  solid;
	border-right-color:  #495057;
	border-right-width:  1px;
}

.left_panel_mobile {

	display:  flex;
	flex-direction:  column;
	align-items:  center;

	width:  100%;

	gap:  .5rem;

	padding:  1rem;

}

.navigation {

	color:  #6C757D;

	font-size:  1.25rem;

	border-bottom-color:  #495057;
	border-bottom-style:  solid;
	border-bottom-width:  1px;

	padding-bottom:  .5rem;
	margin-bottom:  .5rem;

}


.option {

	font-size:  1.5rem;

	text-decoration:  none;

}