
p {
	font-size:  1.25rem;
}

figure figcaption {
	text-align:  center;
}

figcaption {
	font-size:  1.5rem;
	font-weight:  bold;
}

figcaption a {
	font-size:  1rem;
	font-weight:  normal;
}


.face {

	border-radius:  10px;

	margin:  0 1rem 0 0;

	width:  210px;
	height:  327px;

	box-shadow: 0 0 20px black;

}

.bio-body {

	display:  flex;

}

