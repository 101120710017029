
.content-container {

	box-shadow: 5px 5px 20px black;

	border-radius:  10px;

	padding:  .01rem 1rem 1rem 1rem;

	max-width:  70ch;

	background-color: #212529;
}

.dropdown-content-source {
	margin-left:  auto;
	margin-right:  0;
}

.course-data-heading {

	color:  #83b0e1;
	font-weight:  bold;

}

.course-data-field {

	padding:  .5rem;

}

