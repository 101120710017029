

.footer {

  border-top-style:  solid;
  border-top-color:  #495057;
  border-top-width:  1px;

  padding:  .5rem;

}

.lab_link {

  color:  white;
  text-decoration:  underline;


}