
body {

  margin:  0 .5rem 0 .5rem;

  color:  #F8F9FA;
  background-color:  #212529;

  font-family:  Ubuntu;
}

a {

  color:  #F8F9FA;
}

h1 {
  color:  #71A5DE;
}

h2 {
  color:  #6C757D;
}

h3 {
  color:  #F8F9FA;
}

h4 {
  color:  #83b0e1;
}

table{

  border-collapse:collapse;
  
  border:1px solid #495057;

}

table td{

  border:1px solid #495057;

}

.clickable {

}

.clickable:hover {
  cursor:  pointer;
  text-decoration:  underline;
}

.indent1 {

  padding-left: 4ch;
}